.autocompleteMultiSelectPopper .autocompleteMultiSelectPapper ul {
  padding: 10px;
}

.autocompleteMultiSelectPopper .autocompleteMultiSelectPapper li {
  padding: 0;
}

.autocompleteMultiSelectInputRoot .Platform-x-Autocomplete-input {
  display: inline-flex;
}
.autocompleteMultiSelectInputRoot
  .Platform-x-Autocomplete-input.Platform-x-InputBase-inputAdornedStart {
  width: auto;
  display: inline-flex;
}

.autocompleteMultiSelectInputRoot .autocompleteMultiSelectTag {
  margin: 3px;
}
