@import "../../../../libs/themes/src/lib/fonts/font.css";

.edit-mode {
  overflow: hidden;
}

.error {
  border-color: red !important;
}

*[contenteditable="true"] {
  border: 2px dashed #b3d7ff;
  outline: none !important;
  padding: 0 3px;
  border-radius: 3px;
  line-height: 1.5;
}

*[contenteditable="false"] {
  line-height: 1.5;
}

#json-editor-labels {
  display: none !important;
}

#json-editor {
  -webkit-user-modify: read-only;
}

#json-editor[contenteditable="true"] {
  border: none;
}

#json-editor span[type="key"] {
  -webkit-user-modify: read-only;
}

#json-editor span[type="symbol"] {
  -webkit-user-modify: read-only;
}

#json-editor span[type="linebreak"] {
  -webkit-user-modify: read-only;
}

#json-editor span[type="string"] {
  -webkit-user-modify: read-write;
}

#json-editor span[type="error"] {
  -webkit-user-modify: read-write;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

ul::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  width: 0px;
}

/* ===== Scrollbar CSS ===== */
/* width */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 4px;
}

/* toast custom styles */
.Toastify__toast--error {
  border-left: 7px solid #e53935 !important;
  background: #ffffff !important;
  color: #e53935 !important;
  border-radius: 5px !important;
}

.Toastify__toast--success {
  border-left: 7px solid #0fa069 !important;
  background: #ffffff !important;
  color: #0fa069 !important;
  border-radius: 5px !important;
}

.Toastify__toast--warning {
  border-left: 7px solid #e2ab30 !important;
  background: #ffffff !important;
  color: #e2ab30 !important;
  border-radius: 5px !important;
}

.Toastify__toast-body {
  padding-left: 10px;
  width: 100%;
}

.Toastify__toast > button > svg {
  margin-top: 18px;
  color: #a0a3bd !important;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/8a5692be9b4c2d448257.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
