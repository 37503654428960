.contentDisplayWithCategories .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.contentDisplayWithCategories .slick-slider .slick-track,
.contentDisplayWithCategories .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.contentDisplayWithCategories .slick-slide {
  display: block;
  float: left;
  height: 100%;
  min-height: 1px;
}
.contentDisplayWithCategories .slick-prev {
  right: 55px;
  left: auto;
}
.contentDisplayWithCategories .slick-prev,
.contentDisplayWithCategories .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 24px;
  display: block;
  width: 33px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 1;
}
.contentDisplayWithCategories .slick-prev:before,
.contentDisplayWithCategories .slick-next:before {
  font-size: 20px;
  line-height: 10px;
  /*  */
  position: absolute;
  left: 11px;
  top: 9px;
  height: 10px;
  width: 10px;
  opacity: 1;
}
.contentDisplayWithCategories .slick-prev:before {
  content: "‹";
}
.contentDisplayWithCategories .slick-next {
  right: 15px;
}
.contentDisplayWithCategories .slick-next:before {
  content: "›";
  /* margin-left: 0px; */
}
.hotcatmn:last-child {
  margin-bottom: 0;
}
.hotcatmn .icon {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  max-width: 37px;
  min-width: 37px;
  margin-right: 14px;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.hotcatmn .icon img {
  width: 100%;
}
.hotcatmn .contentwp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hotcatmn .righticon {
  margin-left: 15px;
  overflow: hidden;
  max-width: 15px;
  min-width: 15px;
}
.hotcatmn .righticon img {
  position: relative;
  margin-left: -15px;
  transition: all 0.5s;
}
.hotcatmn:hover .righticon img {
  margin-left: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .hotcatmn:nth-child(2n) {
    margin-left: 11px;
  }
}
