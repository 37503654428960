.ecom-coupon-code {
  float: left;
  width: 100%;
  /* padding: 18px 0px; */
  margin-bottom: 16px;
}

.ecom-coupon-code .coupon-code-text {
  cursor: pointer;
}

.ecom-coupon-code .coupon-box {
  display: flex;
  justify-content: space-around;
}

.ecom-coupon-code .custom-textbox input {
  padding: 15px;
}

.ecom-coupon-code button.coupon-apply {
  height: 55px;
  margin-top: 16px;
  min-width: 55px;
  margin-right: 0;
}
